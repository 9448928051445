export const ALBUM_JOHNNY_HORTON = 'albumJohnnyHorton'
export const ALBUM_NAS = 'albumNas'
export const ALBUM_POLICE = 'albumPolice'
export const ALBUM_TELEVISION = 'albumTelevision'
export const BENNETT_MAT = 'bennettMat'
export const BENNETT_OCEAN_FLOOR = 'bennettOceanFloor'
export const BENNETT_ODIN_BUOY = 'bennettOdinBuoy'
export const BENNETT_ODIN_DEPTHS = 'bennettOdinDepths'
export const BENNETT_ODIN_GATE = 'bennettOdinGate'
export const BENNETT_ODIN_SHORE = 'bennettOdinShore'
export const BENNETT_RICKSHAW = 'bennettRickshaw'
export const BENNETT_STAIRS = 'bennettStairs'
export const BUDDHA_CAVE_SHRINE = 'buddhaCaveShrine'
export const CLIPBOARD = 'clipboard'
export const CODE_BOX = 'codeBox'
export const CORKBOARD = 'corkboard'
export const CORKBOARD__DISHROOM = 'corkboard__dishroom'
export const CORKBOARD__SCHOOL = 'corkboard__school'
export const DOGS_ROCKET = 'dogsRocket'
export const DOGS_SPACE_STATION = 'dogsSpaceStation'
export const EARTH = 'earth'
export const EARTH__ROCKET = 'earth__rocket'
export const EARTH__STATION = 'earth__station'
export const MARQUEE_FRAME = 'marqueeFrame'
export const MARQUEE_FRAME__GLADIATOR = 'marqueeFrame__gladiator'
export const MARQUEE_FRAME__MARA_GLADIATOR = 'marqueeFrame__maraGladiator'
export const MARQUEE_FRAME__MARA_MEMENTO = 'marqueeFrame__maraMemento'
export const ODIN_MAT = 'odinMat'
export const ODIN_OCEAN_FLOOR = 'odinOceanFloor'
export const ODIN_RICKSHAW = 'odinRickshaw'
export const ODIN_STAIRS = 'odinStairs'
export const PAINTING = 'painting'
export const PAINTING_EMPRESS = 'paintingEmpress'
export const PAINTING_EMPRESS__MAT = 'paintingEmpress__mat'
export const PAINTING_EMPRESS__STAIRS = 'paintingEmpress__stairs'
export const PAINTING_GODDESS = 'paintingGoddess'
export const PAINTING_GODDESS__MAT = 'paintingGoddess__mat'
export const PAINTING_GODDESS__STAIRS = 'paintingGoddess__stairs'
export const POSTER_BLOOM_COUNTY = 'posterBloomCounty'
export const POSTER_ELLIOTT_SMITH = 'posterElliottSmith'
export const POSTER_GLADIATOR = 'posterGladiator'
export const POSTER_LEONARD_COHEN = 'posterLeonardCohen'
export const POSTER_MARA_GLADIATOR = 'posterMaraGladiator'
export const POSTER_MARA_MEMENTO = 'posterMaraMemento'
export const POSTER_NIRVANA = 'posterNirvana'
export const POSTER_TINTIN = 'posterTintin'
export const PROGRESS_CHART_EARLY = 'progressChartEarly'
export const PROGRESS_CHART_LATE = 'progressChartLate'
export const SEA_SERPENT_LEFT = 'seaSerpentLeft'
export const SEA_SERPENT_RIGHT = 'seaSerpentRight'
export const SNOWGLOBE_CORD = 'snowglobeCord'
export const SNOWGLOBE_COWGIRL = 'snowglobeCowgirl'
export const SNOWGLOBE_ENRAGED_SOBBING = 'snowglobeEnragedSobbing'
export const SNOWGLOBE_LEAVING_CRUMBLED = 'snowglobeLeavingCrumbled'
export const SNOWGLOBE_SMALL_FUTURE = 'snowglobeSmallFuture'
export const SNOWGLOBE_SMALL_PAST = 'snowglobeSmallPast'
export const SNOWGLOBE_SMALL_PRESENT = 'snowglobeSmallPresent'
export const VAN_NUYS_PAINTING = 'vanNuysPainting'
