import atticPillow from '../../../../assets/svgs/furnitures/atticPillow'
import bennettPillow from '../../../../assets/svgs/furnitures/bennettPillow'
import captainBedPillow from '../../../../assets/svgs/furnitures/captainBedPillow'
import deathbedBlanket from '../../../../assets/svgs/furnitures/deathbedBlanket'
import deathbedPillow from '../../../../assets/svgs/furnitures/deathbedPillow'
import lizPillows from '../../../../assets/svgs/furnitures/lizPillows'
import lizPillowsPile from '../../../../assets/svgs/furnitures/lizPillowsPile'
import lizPillowsPileReflection from '../../../../assets/svgs/furnitures/lizPillowsPileReflection'
import lizPillowsReflection from '../../../../assets/svgs/furnitures/lizPillowsReflection'

import {
    ATTIC_PILLOW,
    BENNETT_PILLOW__BED,
    BENNETT_PILLOW__COUCH,
    CAPTAIN_BED_PILLOW,
    DEATHBED_BLANKET,
    DEATHBED_PILLOW,
    LIZ_PILLOWS,
    LIZ_PILLOWS_PILE,
    LIZ_PILLOWS_PILE_REFLECTION,
    LIZ_PILLOWS_REFLECTION,
} from '../../../../constants/scene/things/furnitures'

export default {
    [ATTIC_PILLOW]: atticPillow,
    [BENNETT_PILLOW__BED]: bennettPillow,
    [BENNETT_PILLOW__COUCH]: bennettPillow,
    [CAPTAIN_BED_PILLOW]: captainBedPillow,
    [DEATHBED_BLANKET]: deathbedBlanket,
    [DEATHBED_PILLOW]: deathbedPillow,
    [LIZ_PILLOWS]: lizPillows,
    [LIZ_PILLOWS_PILE]: lizPillowsPile,
    [LIZ_PILLOWS_PILE_REFLECTION]: lizPillowsPileReflection,
    [LIZ_PILLOWS_REFLECTION]: lizPillowsReflection,
}
