// extracted by mini-css-extract-plugin
export var COLOUR__FUTURE__DARK = "#a87dc5";
export var COLOUR__FUTURE__DARKER = "#9e73ba";
export var COLOUR__FUTURE__LIGHT = "#e9d4f7";
export var COLOUR__FUTURE__LIGHTER = "#f2e5fb";
export var COLOUR__PAST__DARK = "#c5a87d";
export var COLOUR__PAST__DARKER = "#ba9e73";
export var COLOUR__PAST__LIGHT = "#f7e9d4";
export var COLOUR__PAST__LIGHTER = "#fbf2e5";
export var COLOUR__PAST__MEDIUM = "#dec196";
export var COLOUR__PAST__MEDLIGHT = "#e6cda7";
export var COUNT_GAPS_DOUBLE = "26";
export var COUNT_GAPS_SINGLE = "16";
export var HEIGHT_MENU = "80px";
export var HEIGHT_NAV = "52px";
export var HUE_APARTMENT = "330";
export var HUE_BENNETT_LINENS = "4";
export var HUE_BENNETT_ROOM = "15";
export var HUE_FAMILY = "60";
export var HUE_FUTURE = "276";
export var HUE_LIZ_LINENS = "205";
export var HUE_LIZ_ROOM = "182";
export var HUE_MAGIC = "125";
export var HUE_MIRIAM_LINENS = "75";
export var HUE_OCEAN = "204";
export var HUE_PAST = "36";
export var HUE_PRESENT = "156";
export var HUE_ROOF = "7";
export var HUE_SAND = "60";
export var HUE_SNOW = "186";
export var HUE_TENNIS = "220";
export var HUE_WAITING = "43";
export var LENGTH_ICON = "44px";
export var LENGTH_ICON_LARGE = "54px";
export var LENGTH_ICON_LARGE_MINI = "47px";
export var LENGTH_ICON_LARGE_TABLET = "50px";
export var MARGIN_THIN = "4px";
export var OVERLAP_MARGIN_X_SLIDER = "2px";
export var PADDING_POPUP_CONTENT_WIDE = "20px";
export var TRANSITION_DURATION_CURTAINS = ".25s";
export var TRANSITION_DURATION_PRESENCE = ".25s";
export var WIDTH_GOLDEN_CORD = "745px";
export var WIDTH_LAPTOP = "1400px";
export var WIDTH_MENU_BREAKPOINT = "800px";
export var WIDTH_UNCANNY_VALLEY = "570px";