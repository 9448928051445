export const BUBBLE_LARGE = 'bubbleLarge'
export const BUBBLE_LARGE__AMY = 'bubbleLarge__amy'
export const BUBBLE_LARGE__CINEMA = 'bubbleLarge__cinema'
export const BUBBLE_LARGE__DEATHBED = 'bubbleLarge__deathbed'
export const BUBBLE_LARGE__DISHROOM = 'bubbleLarge__dishroom'
export const BUBBLE_LARGE__GOLDEN = 'bubbleLarge__golden'
export const BUBBLE_LARGE__GO_KART = 'bubbleLarge__goKart'
export const BUBBLE_LARGE__INVERSE = 'bubbleLarge__inverse'
export const BUBBLE_LARGE__REUNION = 'bubbleLarge__reunion'
export const BUBBLE_LARGE__RIGHT = 'bubbleLarge__right'
export const BUBBLE_LARGE__SCHOOLBUS = 'bubbleLarge__schoolbus'
export const BUBBLE_LARGE__TCHOTCHKES = 'bubbleLarge__tchotchkes'
export const BUBBLE_LARGE__TENNIS = 'bubbleLarge__tennis'
export const BUBBLE_LARGE__WAITING = 'bubbleLarge__waiting'
export const BUBBLE_MEDIUM = 'bubbleMedium'
export const BUBBLE_MEDIUM__AMY = 'bubbleMedium__amy'
export const BUBBLE_MEDIUM__CINEMA = 'bubbleMedium__cinema'
export const BUBBLE_MEDIUM__DEATHBED = 'bubbleMedium__deathbed'
export const BUBBLE_MEDIUM__DISHROOM = 'bubbleMedium__dishroom'
export const BUBBLE_MEDIUM__GOLDEN = 'bubbleMedium__golden'
export const BUBBLE_MEDIUM__GO_KART = 'bubbleMedium__goKart'
export const BUBBLE_MEDIUM__INVERSE = 'bubbleMedium__inverse'
export const BUBBLE_MEDIUM__REUNION = 'bubbleMedium__reunion'
export const BUBBLE_MEDIUM__RIGHT = 'bubbleMedium__right'
export const BUBBLE_MEDIUM__SCHOOLBUS = 'bubbleMedium__schoolbus'
export const BUBBLE_MEDIUM__TCHOTCHKES = 'bubbleMedium__tchotchkes'
export const BUBBLE_MEDIUM__TENNIS = 'bubbleMedium__tennis'
export const BUBBLE_MEDIUM__WAITING = 'bubbleMedium__waiting'
export const BUBBLE_SMALL = 'bubbleSmall'
export const BUBBLE_SMALL__AMY = 'bubbleSmall__amy'
export const BUBBLE_SMALL__CINEMA = 'bubbleSmall__cinema'
export const BUBBLE_SMALL__DEATHBED = 'bubbleSmall__deathbed'
export const BUBBLE_SMALL__DISHROOM = 'bubbleSmall__dishroom'
export const BUBBLE_SMALL__GOLDEN = 'bubbleSmall__golden'
export const BUBBLE_SMALL__GO_KART = 'bubbleSmall__goKart'
export const BUBBLE_SMALL__INVERSE = 'bubbleSmall__inverse'
export const BUBBLE_SMALL__REUNION = 'bubbleSmall__reunion'
export const BUBBLE_SMALL__RIGHT = 'bubbleSmall__right'
export const BUBBLE_SMALL__SCHOOLBUS = 'bubbleSmall__schoolbus'
export const BUBBLE_SMALL__TCHOTCHKES = 'bubbleSmall__tchotchkes'
export const BUBBLE_SMALL__TENNIS = 'bubbleSmall__tennis'
export const BUBBLE_SMALL__WAITING = 'bubbleSmall__waiting'
export const CINEMA_THOUGHT_BUYING = 'cinemaThoughtBuying'
export const CINEMA_THOUGHT_RECORDING = 'cinemaThoughtRecording'
export const CONSTELLATION_SIBLING = 'constellationSibling'
export const CONSTELLATION_TARPIT = 'constellationTarpit'
export const KHARI_SPEECH_ATTACK = 'khariSpeechAttack'
export const LIZ_SPEECH_DEFENCE = 'lizSpeechDefence'
export const LIZ_THOUGHT_BUSING = 'lizThoughtBusing'
export const LIZ_THOUGHT_DINING = 'lizThoughtDining'
export const LIZ_THOUGHT_DISHROOM = 'lizThoughtDishroom'
export const LIZ_THOUGHT_REUNION = 'lizThoughtReunion'
export const PHOTO_ALBUM = 'photoAlbum'
export const SKETCHBOOK = 'sketchbook'
export const SKETCHBOOK__APART = 'sketchbook__apart'
export const SKETCHBOOK__DOGS_ROCKET = 'sketchbook__dogsRocket'
export const SKETCHBOOK__ESCAPE_POD = 'sketchbook__escapePod'
export const SKETCHBOOK__TOGETHER = 'sketchbook__together'
export const SKETCH_APART = 'sketchApart'
export const SKETCH_DOGS_ROCKET = 'sketchDogsRocket'
export const SKETCH_ESCAPE_POD = 'sketchEscapePod'
export const SKETCH_TOGETHER = 'sketchTogether'
export const THOUGHT_AMY = 'thoughtAmy'
export const THOUGHT_GO_KART = 'thoughtGoKart'
export const WAITING_THOUGHT_SINGING = 'waitingThoughtSinging'
export const WAITING_THOUGHT_STUDYING = 'waitingThoughtStudying'
export const WAITING_THOUGHT_WALKING = 'waitingThoughtWalking'
