import stooping from '../../../../assets/svgs/actors/mother/stooping'
import comforting from '../../../../assets/svgs/actors/mother/comforting'
import arguing from '../../../../assets/svgs/actors/mother/arguing'
import present from '../../../../assets/svgs/actors/mother/present'
import future from '../../../../assets/svgs/actors/mother/future'
import epilogue from '../../../../assets/svgs/actors/mother/epilogue'

import {
    STOOPING,
    COMFORTING,
    ARGUING,
} from '../../../../constants/scene/actors/mother'

import {
    PRESENT,
    FUTURE,
    EPILOGUE,
} from '../../../../constants/scene/actors/songs'

export default {
    [STOOPING]: stooping,
    [COMFORTING]: comforting,
    [ARGUING]: arguing,
    [PRESENT]: present,
    [FUTURE]: future,
    [EPILOGUE]: epilogue,
}
