export const ATTIC_ARMREST = 'atticArmrest'
export const ATTIC_ARMREST__LEFT = 'atticArmrest__left'
export const ATTIC_ARMREST__RIGHT = 'atticArmrest__right'
export const ATTIC_COUCH = 'atticCouch'
export const BATHTUB = 'bathtub'
export const BEDROOM_DESK = 'bedroomDesk'
export const BEDROOM_STOOL = 'bedroomStool'
export const BENNETT_BED = 'bennettBed'
export const BENNETT_NIGHTSTAND = 'bennettNightstand'
export const BENNETT_RECORD_STAND = 'bennettRecordStand'
export const BUDDHA_STAND = 'buddhaNightstand'
export const CAPTAIN_BED = 'captainBed'
export const CAPTAIN_BED_INTERIOR = 'captainBedInterior'
export const CAPTAIN_BED_LIT = 'captainBedLit'
export const CAVE_SHADOWS = 'caveShadows'
export const CONVEYOR_BELT = 'conveyorBelt'
export const CONVEYOR_BELT__READING = 'conveyorBelt__reading'
export const CONVEYOR_BELT__LANCET = 'conveyorBelt__lancet'
export const COUCH_SHELF = 'couchShelf'
export const CROWD_BACK = 'crowdBack'
export const CROWD_SIDE = 'crowdSide'
export const DISHROOM_SINK = 'dishroomSink'
export const FREEWAY_FENCE = 'freewayFence'
export const GO_KART_EXTERIOR = 'goKartExterior'
export const GO_KART_EXTERIOR__LEFT = 'goKartExterior__left'
export const GO_KART_EXTERIOR__RIGHT = 'goKartExterior__right'
export const GO_KART_SEAT = 'goKartSeat'
export const GO_KART_SEAT__LEFT = 'goKartSeat__left'
export const GO_KART_SEAT__RIGHT = 'goKartSeat__right'
export const HOSPITAL_BED = 'hospitalBed'
export const HOSPITAL_BED_INTERIOR = 'hospitalBedInterior'
export const HOSPITAL_RECORD_STAND = 'hospitalRecordStand'
export const KEYBOARD_STAND_LEFT = 'keyboardStandLeft'
export const KEYBOARD_STAND_RIGHT = 'keyboardStandRight'
export const LIZ_BED = 'lizBed'
export const LIZ_COUCH = 'lizCouch'
export const LIZ_COUCH__FUTURE = 'lizCouch__future'
export const LIZ_COUCH__PRESENT = 'lizCouch__present'
export const LIZ_COUCH__ROOM = 'lizCouch__room'
export const LIZ_NIGHTSTAND = 'lizNightstand'
export const LIZ_OTTOMAN = 'lizCouch__ottoman'
export const MILK_CRATE = 'milkCrate'
export const MILK_CRATE__ANA = 'milkCrate__ana'
export const MILK_CRATE__BENNETT = 'milkCrate__bennett'
export const MILK_CRATE__JACOB = 'milkCrate__jacob'
export const MILK_CRATE__MARA = 'milkCrate__mara'
export const MILK_CRATE__VEGAN = 'milkCrate__vegan'
export const NURSE_BENCH = 'nurseBench'
export const OCEAN_CAVE_EXTERIOR_LEFT = 'oceanCaveExteriorLeft'
export const OCEAN_CAVE_EXTERIOR_RIGHT = 'oceanCaveExteriorRight'
export const OCEAN_CAVE_INTERIOR_LEFT = 'oceanCaveInteriorLeft'
export const OCEAN_CAVE_INTERIOR_RIGHT = 'oceanCaveInteriorRight'
export const PHONE_CALL_PANEL = 'phoneCallPanel'
export const PRINCIPAL_BENCH = 'principalBench'
export const REATTA_EXTERIOR_FRONT = 'reattaExteriorFront'
export const REATTA_EXTERIOR_REAR = 'reattaExteriorRear'
export const REATTA_INTERIOR = 'reattaInterior'
export const REATTA_SEAT_BACK = 'reattaSeatBack'
export const REATTA_SEAT_BACK_DOWN = 'reattaSeatBackDown'
export const REATTA_SEAT_BOTTOM = 'reattaSeatBottom'
export const SATURN_EXTERIOR = 'saturnExterior'
export const SATURN_INTERIOR = 'saturnInterior'
export const SATURN_SEAT = 'saturnSeat'
export const SATURN_SEAT_DOWN = 'saturnSeatDown'
export const SCHOOLBUS_EXTERIOR = 'schoolbusExterior'
export const SCHOOLBUS_INTERIOR = 'schoolbusInterior'
export const SCHOOLBUS_SEAT = 'schoolbusSeat'
export const SCHOOLBUS_SEAT__LEFT = 'schoolbusSeat__left'
export const SCHOOLBUS_SEAT__RIGHT = 'schoolbusSeat__right'
export const SHADOW_MONGOL = 'shadowMongol'
export const SHADOW_VIKING_FAR = 'shadowVikingFar'
export const SHADOW_VIKING_NEAR = 'shadowVikingNear'
export const SNOWGLOBE_PAST = 'snowglobePast'
export const SNOWGLOBE_PRESENT = 'snowglobePresent'
export const SNOWGLOBE_PRESENT__BENNETT = 'snowglobePresent__bennett'
export const SNOWGLOBE_PRESENT__LIZ = 'snowglobePresent__liz'
export const SNOWGLOBE_FUTURE = 'snowglobeFuture'
export const SNOWGLOBE_FUTURE__BENNETT = 'snowglobeFuture__bennett'
export const SNOWGLOBE_FUTURE__LIZ = 'snowglobeFuture__liz'
export const STALACTITES = 'stalactites'
export const TAIWAN_BRIDGE = 'taiwanBridge'
export const TAIWAN_HAZE = 'taiwanHaze'
export const TAIWAN_HAZE__CLOUD = 'taiwanHaze__cloud'
export const TAIWAN_HAZE__FOG = 'taiwanHaze__fog'
export const TROLL_SHADOW_FAR = 'trollShadowFar'
export const TROLL_SHADOW_LEFT = 'trollShadowLeft'
export const TROLL_SHADOW_RIGHT = 'trollShadowRight'
export const VOLKSWAGEN = 'volkswagen'
export const WAITING_ARMCHAIR = 'waitingArmchair'
export const WAITING_ARMCHAIR__BENNETT = 'waitingArmchair__bennett'
export const WAITING_ARMCHAIR__LIZ = 'waitingArmchair__liz'
export const WAITING_ARMREST = 'waitingArmrest'
export const WAITING_ARMREST__BENNETT_LEFT = 'waitingArmrest__bennettLeft'
export const WAITING_ARMREST__BENNETT_RIGHT = 'waitingArmrest__bennettRight'
export const WAITING_ARMREST__LIZ_LEFT = 'waitingArmrest__lizLeft'
export const WAITING_ARMREST__LIZ_RIGHT = 'waitingArmrest__lizRight'
export const WEDDING_PLATFORM_FRONT = 'weddingPlatformFront'
export const WEDDING_PLATFORM_REAR = 'weddingPlatformRear'
export const WIDE_ARMREST_BENNETT = 'wideArmrestBennett'
export const WIDE_ARMREST_BENNETT__LEFT = 'wideArmrestBennett__left'
export const WIDE_ARMREST_BENNETT__RIGHT = 'wideArmrestBennett__right'
export const WIDE_ARMREST_MIRIAM = 'wideArmrestMiriam'
export const WIDE_ARMREST_MIRIAM__LEFT = 'wideArmrestMiriam__left'
export const WIDE_ARMREST_MIRIAM__RIGHT = 'wideArmrestMiriam__right'
export const WIDE_COUCH_BENNETT = 'wideCouchBennett'
export const WIDE_COUCH_MIRIAM = 'wideCouchMiriam'
