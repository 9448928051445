import React from 'react'
import cx from 'classnames'
import './style'

const LoadingDramaMasks = () => (
    <svg
        {...{
            className: cx(
                'LoadingDramaMasks',
                'dramaMasks',
            ),
            viewBox: '0 0 469.74 265.56',
            xmlns: 'http://www.w3.org/2000/svg',
        }}
    >
        <g className="mask">
            <path
                d="M273.19,178.35c7,19.77,32.57,20.12,43,4.42,4.14-6.37,2.95-10.75-2.91-13.37-25.36,12.42-54.11,9.26-67.65-12.66-3.84-6.21-5.65-14.29-6.16-20.61a18.23,18.23,0,0,1,3.9-12.45,15.55,15.55,0,0,0,3.89-10.12c.43-6.23,5.18-10.55,13.62-13.23,7.64-2.35,15-8.21,20.24-14.41,9-1.72,16.45,2.2,24.35,8.37,1.75-11.11-6.72-18.24-18.75-17.68,1.86-9.29,9.62-11.94,17.53-24,6.35-10.9,20.14-21.11,32.51-24.3-3.87,8.34,2.82,15.54,14.78,22.18,20.22,9.2,40.2,12.39,63.14,10.7,7.15-.76,10.25-4.32,8.85-11.86a31.15,31.15,0,0,1,13.15-26.69c8.46-6,14.67-12,17.49-17.68,3.2-6.21,9.4-5.08,11.39,2.73,6.66,23.22,3,44.83-13.84,62.33-6.39,7.15-7,15.66-6,25.22,1.55,15.35,1.45,30.11-1.17,44-5.14,22.49-17.73,38.42-38.13,47.48-13.64,5.37-27.27,6.5-41.25,5.05-12.16-1.18-23.44-.24-32.3,5.06-13.52,8.24-26.49,13.08-38.53,10.87-17.73-3.51-28.74-12.64-31.44-32.46A53,53,0,0,0,273.19,178.35Zm81.47-45c10.12-16.86-10.31-37.29-27.16-27.77C339.11,112.32,349.06,121.22,354.66,133.36Z" />
            <path
                d="M237.82,246.47c-4.31,8.5-13.07,11.91-27.42,12.76-8.71.82-14.43,0-23.69,4.08-8.17,3-21,.41-30-5.58-9.66-6.73-21.78-11.85-34.83-12-21.26-.1-38.17-7-53.37-21.26-20.63-21.1-17.34-52.75-7.63-87.39,1.36-4.23-.41-8.31-3.4-6.54C40.75,141,21,140,4,132.86c-3.68-1.81-4.63-7.52,1-9.46a60.9,60.9,0,0,0,21-13.52c8.24-9.68,26.92-13.14,37.68-10.82a29.37,29.37,0,0,0,21.8-3c23.24,15.62,50.9,19.79,81.1,13.18,12.42-3.06,18-9,22.65-17.23,21.92,14,33.61,34.2,36.15,59.81-1.53,4.51-11.78,3.53-25,.68C207.4,162.8,216.12,168.19,228,164a70,70,0,0,0,22,17.91c5.61,3.39,7.68,7.73,7.78,12.84a36.83,36.83,0,0,0,3.37,13.18c4.67,14.81-7.81,29.92-22,35.31-15.22,5.81-43.87-1.26-51.82-7.68-8.18-6.61-14.65-5.14-19.79,1.25-4.19,5.42-2.45,8.45,2.65,10.59,6.39,2,11.06,2.19,17.76-1.09,3-1.31,6.75-2.78,10.43-2.66C207.12,247.11,226.57,250.06,237.82,246.47ZM138.38,176.8c21.24,15.53,44.11,1.09,39.21-19.6C167.93,167.75,154.64,174,138.38,176.8Z" />
        </g>
    </svg>
)

export default LoadingDramaMasks
