export const a = 10
export const b = 11
export const c = 12
export const d = 13
export const e = 14
export const f = 15
export const g = 16

// For not showing any ceiling pixels.
export const k = 17
