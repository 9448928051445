export const ACOUSTIC_GUITAR__SHARED = 'acousticGuitar'
export const BANJO__SHARED = 'banjo'
export const BASS__SHARED = 'bass'
export const BASS_CASE__SHARED = 'bassCase'
export const CABLE__SHARED = 'cable'
export const CYMBAL__SHARED = 'cymbal'
export const DOBRO__SHARED = 'dobro'
export const DRUMS__SHARED = 'drums'
export const DRUMSTICK__SHARED = 'drumstick'
export const ELECTRIC_GUITAR__SHARED = 'electricGuitar'
export const GUITAR_CASE__SHARED = 'guitarCase'
export const HEADPHONE__SHARED = 'headphone'
export const MANDOLIN__SHARED = 'mandolin'
export const MICROPHONE__SHARED = 'microphone'
export const RECORDER__SHARED = 'recorder'
export const VIOLIN__SHARED = 'violin'
