export const CAUTIOUS = 'cautious'
export const ENRAGED = 'enraged'
export const FEARFUL = 'fearful'
export const PANICKED = 'panicked'
export const MAT = 'mat'
export const STAIRS = 'stairs'
export const GATE = 'gate'
export const RICKSHAW = 'rickshaw'
export const SHORE = 'shore'
export const BUOY = 'buoy'
export const OCEAN_FLOOR = 'oceanFloor'
export const FEIGNING_SICK = 'feigningSick'
export const SHAKEN = 'shaken'
export const STOIC = 'stoic'
export const WISTFUL = 'wistful'
export const SHEEPISH = 'sheepish'
