export const BACKPACK_BENNETT = 'backpackBennett'
export const BALLED_FOIL = 'balledFoil'
export const BALLED_FOIL__ALLEY = 'balledFoil__alley'
export const BALLED_FOIL__LOCKERS = 'balledFoil__lockers'
export const BASS_CASE_UPRIGHT = 'bassCaseUpright'
export const BEDROOM_LAMP = 'bedroomLamp'
export const BEDROOM_LAMP__BED = 'bedroomLamp__bed'
export const BEDROOM_LAMP__DESK = 'bedroomLamp__desk'
export const BEETHOVEN_BUST = 'beethovenBust'
export const BEETHOVEN_BUST__BEDROOM = 'beethovenBust__bedroom'
export const BEETHOVEN_BUST__COUCH = 'beethovenBust__couch'
export const BENNETT_COUCH_LAMP = 'bennettCouchLamp'
export const BENNETT_LETTERS = 'bennettLetters'
export const BENNETT_RECORD_PLAYER = 'bennettRecordPlayer'
export const BENNETT_SHOE = 'bennettShoe'
export const BENNETT_SHOE_REFLECTION = 'bennettShoeReflection'
export const BEYONDER_ENVELOPE = 'beyonderEnvelope'
export const BUDWEISER_CASE = 'budweiserCase'
export const BUDWEISER_EMPTIER = 'budweiserEmptier'
export const BUDWEISER_EMPTIER__MARA = 'budweiserEmptier__mara'
export const BUDWEISER_EMPTIER__MIRIAM = 'budweiserEmptier__miriam'
export const BUDWEISER_FULLER = 'budweiserFuller'
export const BUDWEISER_FULLER__BENNETT = 'budweiserFuller__bennett'
export const BUDWEISER_FULLER__MIRIAM = 'budweiserFuller__miriam'
export const CASH_BOX = 'cashBox'
export const CHRISTOPHER_LETTERS = 'christopherLetters'
export const CONDOM_LIZ = 'condomLiz'
export const CONDOM_MIRIAM = 'condomMiriam'
export const CONDOM_REFLECTION = 'condomReflection'
export const CONDOM_WRAPPER_LIZ = 'condomWrapperLiz'
export const CONDOM_WRAPPER_MIRIAM = 'condomWrapperMiriam'
export const CONDOM_WRAPPER_REFLECTION = 'condomWrapperReflection'
export const CRACKED_PICTURE = 'crackedPicture'
export const DRUMKIT = 'drumKit'
export const DRUMS_STACKED = 'drumsStacked'
export const DRUMS_STACKED__BASEMENT = 'drumsStacked__basement'
export const DRUMS_STACKED__CLUB = 'drumsStacked__club'
export const DRUM_THRONE = 'drumThrone'
export const ELECTRIC_GUITAR = 'electricGuitar'
export const EVIAN_BOTTLES = 'evianBottles'
export const FLOWER_BULBS = 'flowerBulbs'
export const FREEWAY_SIGN = 'freewaySign'
export const FRIDGE_DRAWINGS = 'fridgeDrawings'
export const GHOST_SHACKLES = 'ghostShackles'
export const GUITAR_CASE_OPEN = 'guitarCaseOpen'
export const GUITAR_CASE_UPRIGHT = 'guitarCaseUpright'
export const HAIRCUT_SCISSORS = 'haircutScissors'
export const HAWAIIAN_DOLL = 'hawaiianDoll'
export const HEINEKEN = 'heineken'
export const HEINEKEN__BENNETT = 'heineken__bennett'
export const HEINEKEN__SITA = 'heineken__sita'
export const HEINEKEN__WILLY = 'heineken__willy'
export const HEINEKEN_PACK = 'heinekenPack'
export const HOSPITAL_RECORD_PLAYER = 'hospitalRecordPlayer'
export const KEYBOARD = 'keyboard'
export const KICK_DRUM = 'kickDrum'
export const LETTER_SCRAPS = 'letterScraps'
export const LETTER_SCRAPS__BENNETT = 'letterScraps__bennett'
export const LETTER_SCRAPS__CHRISTOPHER = 'letterScraps__christopher'
export const LIGHT_SWITCH = 'lightSwitch'
export const LIGHT_SWITCH__OFF = 'lightSwitch__off'
export const LIGHT_SWITCH__ON = 'lightSwitch__on'
export const LINGERIE_DRAWING = 'lingerieDrawing'
export const LIZ_PHONE = 'lizPhone'
export const LIZ_PHONE_BASE = 'lizPhoneBase'
export const LIZ_PHONE_BASE_REFLECTION = 'lizPhoneBaseReflection'
export const LIZ_PHONE_REFLECTION = 'lizPhoneReflection'
export const MANNEQUIN = 'mannequin'
export const PLAYGROUND_BALL = 'playgroundBall'
export const PUSH_BROOM = 'pushBroom'
export const RECORDING_MIC = 'recordingMic'
export const RECORD_PLAYER_STOOL = 'recordPlayerStool'
export const RED_ENVELOPE_MONEY = 'redEnvelopeMoney'
export const SAFEWAY_BAG = 'safewayBag'
export const SHELF_BOOKS_EARLY = 'shelfBooksEarly'
export const SHELF_BOOKS_LATE = 'shelfBooksLate'
export const SHELF_CUP = 'shelfCup'
export const STAGE_MIC = 'stageMic'
export const STAGE_MIC__CROWD = 'stageMic__crowd'
export const STAGE_MIC__STAGE = 'stageMic__stage'
export const STEPLADDER = 'stepladder'
export const TCHOTCHKES = 'tchotchkes'
export const TENNIS_BALL = 'tennisBall'
export const TETHERBALL_POLE = 'tetherballPole'
export const THROWN_FRUIT = 'thrownFruit'
export const THROWN_FRUIT__ALLEY = 'thrownFruit__alley'
export const THROWN_FRUIT__LOCKERS = 'thrownFruit__lockers'
export const TORN_MAGAZINES = 'tornMagazines'
export const TORN_MAGAZINES__BENNETT = 'tornMagazines__bennett'
export const TORN_MAGAZINES__CHRISTOPHER = 'tornMagazines__christopher'
export const VASELINE = 'vaseline'
export const WINE_BOTTLE = 'wineBottle'
