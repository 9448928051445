import React from 'react'
import './style'

const AboutCredits = () => (
    <div
        {...{
            className: 'AboutCredits',
        }}
    >
        {`Album written, composed, and performed by Bennett Lin.`}
        <br />
        {'Website annotated, illustrated, and coded by Bennett Lin.'}
    </div>
)

export default AboutCredits
