export const GINGERBREAD_PRISON = 'gingerbreadPrison'
export const CHEEK_UNVEILED = 'cheekUnveiled'
export const HARD_ON = 'hardOn'
export const HARD_ON_REFLECTION = 'hardOnReflection'
export const MISSIONARY = 'missionary'
export const MISSIONARY_REFLECTION = 'missionaryReflection'
export const AFTERGLOW = 'afterglow'
export const AFTERGLOW_REFLECTION = 'afterglowReflection'
export const BRUSH_OFF = 'brushOff'
export const CELIBATE_FREEZING = 'celibateFreezing'
export const AWKWARD_CONSOLATION = 'awkwardConsolation'
export const PAMPAS = 'pampas'
export const GETTING_DARK = 'gettingDark'
export const CLIFF = 'cliff'
export const FINAL_DREAM = 'finalDream'
