import bubbleLarge from '../../../../assets/svgs/bubbles/bubbleLarge'
import bubbleMedium from '../../../../assets/svgs/bubbles/bubbleMedium'
import bubbleSmall from '../../../../assets/svgs/bubbles/bubbleSmall'
import cinemaThoughtBuying from '../../../../assets/svgs/bubbles/cinemaThoughtBuying'
import cinemaThoughtRecording from '../../../../assets/svgs/bubbles/cinemaThoughtRecording'
import constellationSibling from '../../../../assets/svgs/bubbles/constellationSibling'
import constellationTarpit from '../../../../assets/svgs/bubbles/constellationTarpit'
import khariSpeechAttack from '../../../../assets/svgs/bubbles/khariSpeechAttack'
import lizSpeechDefence from '../../../../assets/svgs/bubbles/lizSpeechDefence'
import lizThoughtBusing from '../../../../assets/svgs/bubbles/lizThoughtBusing'
import lizThoughtDining from '../../../../assets/svgs/bubbles/lizThoughtDining'
import lizThoughtDishroom from '../../../../assets/svgs/bubbles/lizThoughtDishroom'
import lizThoughtReunion from '../../../../assets/svgs/bubbles/lizThoughtReunion'
import photoAlbum from '../../../../assets/svgs/bubbles/photoAlbum'
import sketchApart from '../../../../assets/svgs/bubbles/sketchApart'
import sketchDogsRocket from '../../../../assets/svgs/bubbles/sketchDogsRocket'
import sketchEscapePod from '../../../../assets/svgs/bubbles/sketchEscapePod'
import sketchTogether from '../../../../assets/svgs/bubbles/sketchTogether'
import sketchbook from '../../../../assets/svgs/bubbles/sketchbook'
import thoughtAmy from '../../../../assets/svgs/bubbles/thoughtAmy'
import thoughtGoKart from '../../../../assets/svgs/bubbles/thoughtGoKart'
import waitingThoughtSinging from '../../../../assets/svgs/bubbles/waitingThoughtSinging'
import waitingThoughtStudying from '../../../../assets/svgs/bubbles/waitingThoughtStudying'
import waitingThoughtWalking from '../../../../assets/svgs/bubbles/waitingThoughtWalking'

import {
    BUBBLE_LARGE__AMY,
    BUBBLE_LARGE__CINEMA,
    BUBBLE_LARGE__DEATHBED,
    BUBBLE_LARGE__DISHROOM,
    BUBBLE_LARGE__GOLDEN,
    BUBBLE_LARGE__GO_KART,
    BUBBLE_LARGE__INVERSE,
    BUBBLE_LARGE__SCHOOLBUS,
    BUBBLE_LARGE__REUNION,
    BUBBLE_LARGE__RIGHT,
    BUBBLE_LARGE__TCHOTCHKES,
    BUBBLE_LARGE__TENNIS,
    BUBBLE_LARGE__WAITING,
    BUBBLE_MEDIUM__AMY,
    BUBBLE_MEDIUM__CINEMA,
    BUBBLE_MEDIUM__DEATHBED,
    BUBBLE_MEDIUM__DISHROOM,
    BUBBLE_MEDIUM__GOLDEN,
    BUBBLE_MEDIUM__GO_KART,
    BUBBLE_MEDIUM__INVERSE,
    BUBBLE_MEDIUM__SCHOOLBUS,
    BUBBLE_MEDIUM__REUNION,
    BUBBLE_MEDIUM__RIGHT,
    BUBBLE_MEDIUM__TCHOTCHKES,
    BUBBLE_MEDIUM__TENNIS,
    BUBBLE_MEDIUM__WAITING,
    BUBBLE_SMALL__AMY,
    BUBBLE_SMALL__CINEMA,
    BUBBLE_SMALL__DEATHBED,
    BUBBLE_SMALL__DISHROOM,
    BUBBLE_SMALL__GOLDEN,
    BUBBLE_SMALL__GO_KART,
    BUBBLE_SMALL__INVERSE,
    BUBBLE_SMALL__SCHOOLBUS,
    BUBBLE_SMALL__REUNION,
    BUBBLE_SMALL__RIGHT,
    BUBBLE_SMALL__TCHOTCHKES,
    BUBBLE_SMALL__TENNIS,
    BUBBLE_SMALL__WAITING,
    CINEMA_THOUGHT_BUYING,
    CINEMA_THOUGHT_RECORDING,
    CONSTELLATION_SIBLING,
    CONSTELLATION_TARPIT,
    KHARI_SPEECH_ATTACK,
    LIZ_SPEECH_DEFENCE,
    LIZ_THOUGHT_BUSING,
    LIZ_THOUGHT_DINING,
    LIZ_THOUGHT_DISHROOM,
    LIZ_THOUGHT_REUNION,
    PHOTO_ALBUM,
    SKETCHBOOK__APART,
    SKETCHBOOK__DOGS_ROCKET,
    SKETCHBOOK__ESCAPE_POD,
    SKETCHBOOK__TOGETHER,
    SKETCH_APART,
    SKETCH_DOGS_ROCKET,
    SKETCH_ESCAPE_POD,
    SKETCH_TOGETHER,
    THOUGHT_AMY,
    THOUGHT_GO_KART,
    WAITING_THOUGHT_SINGING,
    WAITING_THOUGHT_STUDYING,
    WAITING_THOUGHT_WALKING,
} from '../../../../constants/scene/things/bubbles'

export default {
    [BUBBLE_LARGE__AMY]: bubbleLarge,
    [BUBBLE_LARGE__CINEMA]: bubbleLarge,
    [BUBBLE_LARGE__DEATHBED]: bubbleLarge,
    [BUBBLE_LARGE__DISHROOM]: bubbleLarge,
    [BUBBLE_LARGE__GOLDEN]: bubbleLarge,
    [BUBBLE_LARGE__GO_KART]: bubbleLarge,
    [BUBBLE_LARGE__INVERSE]: bubbleLarge,
    [BUBBLE_LARGE__SCHOOLBUS]: bubbleLarge,
    [BUBBLE_LARGE__REUNION]: bubbleLarge,
    [BUBBLE_LARGE__RIGHT]: bubbleLarge,
    [BUBBLE_LARGE__TCHOTCHKES]: bubbleLarge,
    [BUBBLE_LARGE__TENNIS]: bubbleLarge,
    [BUBBLE_LARGE__WAITING]: bubbleLarge,
    [BUBBLE_MEDIUM__AMY]: bubbleMedium,
    [BUBBLE_MEDIUM__CINEMA]: bubbleMedium,
    [BUBBLE_MEDIUM__DEATHBED]: bubbleMedium,
    [BUBBLE_MEDIUM__DISHROOM]: bubbleMedium,
    [BUBBLE_MEDIUM__GOLDEN]: bubbleMedium,
    [BUBBLE_MEDIUM__GO_KART]: bubbleMedium,
    [BUBBLE_MEDIUM__INVERSE]: bubbleMedium,
    [BUBBLE_MEDIUM__SCHOOLBUS]: bubbleMedium,
    [BUBBLE_MEDIUM__REUNION]: bubbleMedium,
    [BUBBLE_MEDIUM__RIGHT]: bubbleMedium,
    [BUBBLE_MEDIUM__TCHOTCHKES]: bubbleMedium,
    [BUBBLE_MEDIUM__TENNIS]: bubbleMedium,
    [BUBBLE_MEDIUM__WAITING]: bubbleMedium,
    [BUBBLE_SMALL__AMY]: bubbleSmall,
    [BUBBLE_SMALL__CINEMA]: bubbleSmall,
    [BUBBLE_SMALL__DEATHBED]: bubbleSmall,
    [BUBBLE_SMALL__DISHROOM]: bubbleSmall,
    [BUBBLE_SMALL__GOLDEN]: bubbleSmall,
    [BUBBLE_SMALL__GO_KART]: bubbleSmall,
    [BUBBLE_SMALL__INVERSE]: bubbleSmall,
    [BUBBLE_SMALL__SCHOOLBUS]: bubbleSmall,
    [BUBBLE_SMALL__REUNION]: bubbleSmall,
    [BUBBLE_SMALL__RIGHT]: bubbleSmall,
    [BUBBLE_SMALL__TCHOTCHKES]: bubbleSmall,
    [BUBBLE_SMALL__TENNIS]: bubbleSmall,
    [BUBBLE_SMALL__WAITING]: bubbleSmall,
    [CINEMA_THOUGHT_BUYING]: cinemaThoughtBuying,
    [CINEMA_THOUGHT_RECORDING]: cinemaThoughtRecording,
    [CONSTELLATION_SIBLING]: constellationSibling,
    [CONSTELLATION_TARPIT]: constellationTarpit,
    [KHARI_SPEECH_ATTACK]: khariSpeechAttack,
    [LIZ_SPEECH_DEFENCE]: lizSpeechDefence,
    [LIZ_THOUGHT_BUSING]: lizThoughtBusing,
    [LIZ_THOUGHT_DINING]: lizThoughtDining,
    [LIZ_THOUGHT_DISHROOM]: lizThoughtDishroom,
    [LIZ_THOUGHT_REUNION]: lizThoughtReunion,
    [PHOTO_ALBUM]: photoAlbum,
    [SKETCHBOOK__APART]: sketchbook,
    [SKETCHBOOK__DOGS_ROCKET]: sketchbook,
    [SKETCHBOOK__ESCAPE_POD]: sketchbook,
    [SKETCHBOOK__TOGETHER]: sketchbook,
    [SKETCH_APART]: sketchApart,
    [SKETCH_DOGS_ROCKET]: sketchDogsRocket,
    [SKETCH_ESCAPE_POD]: sketchEscapePod,
    [SKETCH_TOGETHER]: sketchTogether,
    [THOUGHT_AMY]: thoughtAmy,
    [THOUGHT_GO_KART]: thoughtGoKart,
    [WAITING_THOUGHT_SINGING]: waitingThoughtSinging,
    [WAITING_THOUGHT_STUDYING]: waitingThoughtStudying,
    [WAITING_THOUGHT_WALKING]: waitingThoughtWalking,
}
